import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AllergyRiskAssessment.css';

const AllergyRiskAssessment = () => {
    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState({
        allergens: [],
        environment: [],
        sensitivity: [],
        dailyActivities: [],
        generalHealth: []
    });
    const [postcode, setPostcode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!/^\d{4}$/.test(postcode)) {
            setError('Postcode must be a 4-digit number');
            return;
        }
        setError('');
        try {
            const response = await fetch(`${apiUrl}/geojson1?postcode=${postcode}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (data.features && data.features.length > 0) {
                navigate('/places', { state: { postcode, suburbBoundary: data , pageTitle: 'pharmacy' } });
            } else {
                setError('No data found for the given postcode');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data. Please try again.');
        }
    };

    const handleNext = () => setStep(step + 1);
    const handlePrevious = () => setStep(step - 1);
    const handleReset = () => {
        setStep(0);
        setAnswers({
            allergens: [],
            environment: [],
            sensitivity: [],
            dailyActivities: [],
            generalHealth: []
        });
        setPostcode('');
        setError('');
    };

    // Add this handler function
    const handleOptionChange = (section, option, type) => {
        if (type === 'checkbox') {
            if (answers[section].includes(option)) {
                setAnswers({
                    ...answers,
                    [section]: answers[section].filter(item => item !== option)
                });
            } else {
                setAnswers({
                    ...answers,
                    [section]: [...answers[section], option]
                });
            }
        } else if (type === 'radio') {
            setAnswers({
                ...answers,
                [section]: [option]
            });
        }
    };



    const generateRecommendations = () => {
        let recommendations = [];

        // Allergen-related recommendations
        if (answers.allergens.includes("Pollen")) {
            recommendations.push(
                "Since you've been exposed to pollen, consider installing high-efficiency particulate air (HEPA) filters in your home, especially in rooms where you spend most time, such as bedrooms or living rooms. Keep windows closed during high pollen seasons and try to shower and change clothes after spending time outdoors."
            );
        }
        if (answers.allergens.includes("Pet hair")) {
            recommendations.push(
                "For exposure to pet hair, it's helpful to vacuum regularly using a vacuum with a HEPA filter. Groom pets frequently and try to keep them off furniture and out of bedrooms. If your symptoms persist, consider allergy-friendly pet products or discussing options with a vet."
            );
        }
        if (answers.allergens.includes("Dust or dust mites")) {
            recommendations.push(
                "To minimize dust and dust mites, wash bedding in hot water weekly and use dust-proof covers for mattresses and pillows. Reduce clutter and dust surfaces regularly using a damp cloth. A dehumidifier can help keep the air dry, which can reduce dust mite populations."
            );
        }
        if (answers.allergens.includes("Mold")) {
            recommendations.push(
                "Exposure to mold can worsen allergies. Ensure proper ventilation in bathrooms and other damp areas. Use mold-resistant paint and clean any visible mold immediately with proper cleaning solutions. Consider using a dehumidifier to control moisture in basements or bathrooms."
            );
        }
        if (answers.allergens.includes("Smoke")) {
            recommendations.push(
                "If you have been exposed to smoke, avoid smoking indoors, and consider using air purifiers. Ventilate your home properly, and if necessary, avoid exposure to areas where there is regular smoke, such as certain work environments or public areas."
            );
        }
        if (answers.allergens.includes("Certain food or drink ingredients")) {
            recommendations.push(
                "For food or drink ingredient sensitivities, it's important to keep a food diary and note any reactions. Consult with a healthcare provider to conduct tests for specific food allergies. Be cautious when dining out and always ask about potential allergens in the ingredients."
            );
        }

        // Environment-related recommendations
        if (answers.environment.includes("Increased indoor dust")) {
            recommendations.push(
                "Increased indoor dust could aggravate allergies. Regularly clean surfaces with microfiber cloths or damp mops. Vacuum carpets and upholstery frequently using a HEPA filter vacuum, and consider minimizing the use of heavy drapery and rugs that tend to collect dust."
            );
        }
        if (answers.environment.includes("Moved to area with more pollen")) {
            recommendations.push(
                "If you've moved to a high-pollen area, try to avoid outdoor activities during peak pollen times (usually early morning or late evening). Consider wearing a mask outdoors during high pollen seasons and use saline nasal sprays to rinse away pollen from nasal passages."
            );
        }
        if (answers.environment.includes("New pets at home")) {
            recommendations.push(
                "Introducing new pets can introduce new allergens. Ensure pets are groomed frequently and bathed as needed. You can also use pet dander sprays to reduce the allergen levels in your home. Keep pets off furniture and limit their access to bedrooms."
            );
        }
        if (answers.environment.includes("Increased humidity")) {
            recommendations.push(
                "Increased humidity can foster the growth of mold and dust mites. Use a dehumidifier to maintain indoor humidity below 50%, especially in areas prone to dampness like basements and bathrooms. Ensure your home has good ventilation and use exhaust fans in bathrooms."
            );
        }
        if (answers.environment.includes("Decreased air quality")) {
            recommendations.push(
                "Poor indoor air quality can worsen respiratory symptoms. Consider using air purifiers with HEPA filters, regularly change HVAC filters, and avoid the use of harsh chemical cleaners or air fresheners that can worsen air quality."
            );
        }

        // Sensitivity-related recommendations
        if (answers.sensitivity.includes("Yes, I feel more prone to sneezing or itchy eyes")) {
            recommendations.push(
                "If you're prone to sneezing or itchy eyes, consider allergy testing to identify specific allergens. Over-the-counter antihistamines can provide relief for mild symptoms, but for more severe cases, consult with an allergist for prescription options or immunotherapy."
            );
        }
        if (answers.sensitivity.includes("No, I haven't noticed any changes")) {
            recommendations.push(
                "Since you haven't noticed any changes, it's still important to maintain good indoor air quality, as environmental allergens can build up over time. Monitor your symptoms closely, and consider periodic health check-ups to stay ahead of any developing sensitivities."
            );
        }
        if (answers.sensitivity.includes("Unsure")) {
            recommendations.push(
                "If you're unsure about sensitivity, keep a log of your symptoms and possible triggers for the next few weeks. It may help identify specific allergens or environmental factors contributing to your symptoms."
            );
        }

        // Daily activities-related recommendations
        if (answers.dailyActivities.includes("Frequent outdoor activities")) {
            recommendations.push(
                "For frequent outdoor activities, try to schedule them when pollen counts are lower, such as late afternoon. Wearing sunglasses and a hat can help prevent pollen from getting into your eyes and hair. After outdoor activities, shower to remove any allergens you may have been exposed to."
            );
        }
        if (answers.dailyActivities.includes("Spending time with pets")) {
            recommendations.push(
                "Spending time with pets increases your exposure to pet dander. Regularly wash your hands after petting animals and try to keep pets out of the rooms where you spend the most time, like bedrooms. Consider using HEPA air purifiers in rooms where pets frequently roam."
            );
        }
        if (answers.dailyActivities.includes("Prolonged exposure to air conditioning")) {
            recommendations.push(
                "Prolonged air conditioning exposure can dry out your nasal passages. Make sure your air conditioning system is well-maintained, and consider using a humidifier to keep moisture levels balanced, especially if you notice dry skin or nasal irritation."
            );
        }
        if (answers.dailyActivities.includes("Using cleaning products")) {
            recommendations.push(
                "Some cleaning products can trigger allergic reactions. Use fragrance-free and hypoallergenic cleaning products, or consider switching to natural alternatives like vinegar or baking soda for cleaning. Always ensure proper ventilation when using any cleaning products."
            );
        }
        if (answers.dailyActivities.includes("Poor indoor air circulation")) {
            recommendations.push(
                "Poor indoor air circulation can allow allergens to accumulate. Ensure regular ventilation by opening windows, using exhaust fans, or installing mechanical air circulation systems. Using houseplants that purify the air, such as spider plants or peace lilies, can also help."
            );
        }

        // General health-related recommendations
        if (answers.generalHealth.includes("Climate changes")) {
            recommendations.push(
                "Climate changes can affect allergies. During dry weather, use a humidifier to keep the air moist. In cold weather, try to maintain indoor temperatures that prevent the air from becoming too dry, as this can irritate your respiratory system."
            );
        }
        if (answers.generalHealth.includes("New environments")) {
            recommendations.push(
                "New environments can introduce new allergens. Give yourself time to adjust, and be mindful of any new symptoms you experience. Consider allergy testing if you notice significant reactions when moving to or spending time in a new area."
            );
        }
        if (answers.generalHealth.includes("Indoor dust")) {
            recommendations.push(
                "If you're sensitive to indoor dust, regular cleaning is key. Consider removing carpets, which can trap dust, and opt for hardwood or tile flooring. Dust and vacuum frequently, and use air purifiers to help capture airborne dust particles."
            );
        }
        if (answers.generalHealth.includes("Certain food or drink ingredients")) {
            recommendations.push(
                "If you're sensitive to specific food or drink ingredients, consult with a nutritionist or allergist to identify potential intolerances or allergies. It's essential to carefully read food labels and avoid trigger ingredients."
            );
        }

        // Default recommendation if no specific issues are flagged
        if (recommendations.length === 0) {
            recommendations.push(
                "While you don't seem to have significant allergy concerns based on your responses, it's still a good idea to maintain a clean and allergen-free living environment. Keep an eye on any emerging symptoms and consult a healthcare professional if needed."
            );
        }

        return recommendations;
    };

    const questions = [
        {
            title: "1.1. Recent Exposure to Potential Allergens",
            image: "/images/q1.webp",
            text: "Have you recently been exposed to any of the following? (Select all that apply):",
            options: ["Pollen", "Pet hair", "Dust or dust mites", "Mold", "Smoke", "Certain food or drink ingredients"],
            section: 'allergens',
            type: 'checkbox'
        },
        {
            title: "1.2. Changes in Living Environment",
            image: "/images/q2.jpg",
            text: "Have you noticed any of the following changes in your living environment recently?",
            options: ["Increased indoor dust", "Moved to area with more pollen", "New pets at home", "Increased humidity", "Decreased air quality"],
            section: 'environment',
            type: 'checkbox'
        },
        {
            title: "1.3. Reaction to Environmental Exposure",
            image: "/images/q3.jpg",
            text: "Have you noticed any changes in your health after exposure to certain environments or items?",
            options: [
                "Yes, I feel more prone to sneezing or itchy eyes",
                "No, I haven't noticed any changes",
                "Unsure"
            ],
            section: 'sensitivity',
            type: 'radio'
        },
        {
            title: "2.1. Living Environment Conditions",
            image: "/images/q4.jpg",
            text: "Do any of the following conditions exist in your living environment?",
            options: ["Prolonged exposure to air conditioning", "Spending time with pets", "Frequent outdoor activities", "Using cleaning products", "Poor indoor air circulation"],
            section: 'dailyActivities',
            type: 'checkbox'
        },
        {
            title: "3.1. Sensitivity to Environmental Changes",
            image: "/images/q5.jpg",
            text: "Do you have heightened sensitivity to any of the following?",
            options: ["Climate changes", "New environments", "Indoor dust", "Certain food or drink ingredients"],
            section: 'generalHealth',
            type: 'checkbox'
        }
    ];

    const renderQuestionStep = () => {
        if (step === 0) {
            return (
                <div className="guide-step">
                    <h1 className="guide-title">Welcome to the Allergy Risk Assessment</h1>
                    <div className="guide-image-container">
                        <img src="/images/q0.jpg" alt="Allergy Assessment Welcome" className="guide-image" />
                    </div>
                    <p className="guide-intro">This questionnaire will help us understand your potential allergy risks. Here's what to expect:</p>
                    <ul className="guide-list">
                        <li>You'll answer questions about your environment and health.</li>
                        <li>Each question is designed to gather specific information about potential allergens and your reactions.</li>
                        <li>At the end, you'll receive personalized recommendations.</li>
                        <li>You can then search for nearby pharmacies based on your postcode.</li>
                    </ul>
                    <button onClick={handleNext} className="start-button">Start Assessment</button>
                </div>
            );
        }

        if (step > questions.length) {
            const recommendations = generateRecommendations();
            return (
                <div className="completion-step">
                    <h2 className="completion-title">Thank you for completing the questionnaire!</h2>
                    <div className="recommendations-container">
                        <p className="recommendations-intro">Based on your answers, here are some personalized recommendations:</p>
                        <ul className="recommendations-list">
                            {recommendations.map((recommendation, index) => (
                                <li key={index} className="recommendation-item">
                                    <span className="recommendation-icon">✔</span>
                                    {recommendation}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <p className="disclaimer">
                        Please note: These recommendations are general advice. For specific medical concerns, always consult with a healthcare professional.
                    </p>
                    <section className="hero-section">
                        <div className="container">
                            <div className="hero-content">
                                <h2>Search pharmacies in your area</h2>
                                <form onSubmit={handleSubmit} className="search-form">
                                    <input
                                        type="text"
                                        placeholder="Enter postcode (4 digits)"
                                        value={postcode}
                                        onChange={(e) => setPostcode(e.target.value)}
                                        aria-label="Postcode"
                                        className="input"
                                    />
                                    <button type="submit" className="submit-button">Find Pharmacies</button>
                                </form>
                                {error && <p className="error">{error}</p>}
                            </div>
                        </div>
                    </section>
                    <button onClick={handleReset} className="reset-button">Start Another</button>
                </div>
            );
        }

        const question = questions[step - 1];
        return (
            <div className="question-step">
                <h2 className="question-title">{question.title}</h2>
                <img src={question.image} alt={question.title} className="question-image" />
                <p className="question-text">{question.text}</p>
                {question.options.map((option) => (
                    <div key={option} className="option-container">
                        <input
                            type={question.type}
                            id={option}
                            name={question.section}
                            value={option}
                            onChange={() => handleOptionChange(question.section, option, question.type)}
                            checked={answers[question.section].includes(option)}
                            className="option-input"
                        />
                        <label htmlFor={option} className="option-label">{option}</label>
                    </div>
                ))}
                {question.type === 'checkbox' && (
                    <input type="text" placeholder="Other (Please describe)" className="other-input" />
                )}
            </div>
        );
    };

    return (
        <div className="questionnaire-container">
            <div className="progress-bar">
                <div className="progress" style={{width: `${(step / (questions.length + 1)) * 100}%`}}></div>
            </div>
            <div className="question-content">
                {renderQuestionStep()}
            </div>
            {step > 0 && step <= questions.length && (
                <div className="button-container">
                    {step > 1 && (
                        <button onClick={handlePrevious} className="previous-button">
                            Previous
                        </button>
                    )}
                    {step < questions.length && (
                        <button onClick={handleNext} className="next-button">
                            Next
                        </button>
                    )}
                    {step === questions.length && (
                        <button onClick={handleNext} className="submit-button">
                            View Results
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};


export default AllergyRiskAssessment;