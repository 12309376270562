import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/Homepage/HomePage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import PasswordPrompt from './components/Password/PasswordPrompt';
import PollenForecastPage from './pages/PollenData/PollenForecastPage';
import HospitalsList from './pages/MapPoints/HospitalsList.js';
import AllergyRiskAssessment from './pages/AllergyRiskAssessment/AllergyRiskAssessment.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import AllergyPage from './pages/PollenInfo/AllergyPage.js';
import AllergyRecognitionPage from './pages/AllergyRecognition/AllergyRecognitionPage.js';
import NotFound from './pages/NotFound/NotFound.js';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    return (
        <div className="App">
            {isAuthenticated ? (
                <Router>
                    <Header />
                    <main>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/health" element={<AllergyPage />} />
                            <Route path="/pollen" element={<PollenForecastPage />} />
                            <Route path="/places" element={<HospitalsList />} />
                            <Route path="/allergy" element={<AllergyRiskAssessment />} />
                            <Route path="/allergyrecognition" element={<AllergyRecognitionPage />} />
                          
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </main>
                   
                    <Footer />
                </Router>
            ) : (
                <PasswordPrompt onPasswordVerified={setIsAuthenticated} />
            )}
        </div>
    );
}

export default App;