import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, Grid } from '@mui/material';

const NotFound = () => {
    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: 'url(error.jpg)', // Background image for the page
                backgroundSize: 'cover',
                textAlign: 'center',
                color: '#fff', // White text color for contrast
                padding: '20px',
                position: 'relative', // Positioning for the overlay
            }}
        >
            {/* Overlay Box */}
            <Box 
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.45)', // Black overlay with transparency
                    zIndex: 0, // Ensure the overlay is behind the content
                }}
            />
            
            <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2, position: 'relative', zIndex: 1 }}>
                Oops! Looks Like You're Lost in the Pollen.
            </Typography>
            <Typography variant="h6" sx={{ mb: 4, position: 'relative', zIndex: 1 }}>
                The page you’re looking for can’t be found.
            </Typography>
            <img 
                src="women404.png" 
                alt="Confused character" 
                style={{ width: '200px', marginBottom: '20px', position: 'relative', zIndex: 1 }} 
            />
            <Typography variant="body1" sx={{ mb: 2, position: 'relative', zIndex: 1 }}>
                But don’t worry! Here are some helpful links instead:
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ position: 'relative', zIndex: 1 }}>
                <Grid item>
                    <Button variant="contained" component={Link} to="/pollen">
                        Pollen Insights
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" component={Link} to="/health">
                        Health Tips
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" component={Link} to="/allergy">
                        Allergy Assessment
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" component={Link} to="/allergyrecognition">
                        Allergy Detection
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="body2" sx={{ marginTop: '20px', position: 'relative', zIndex: 1 }}>
                Let’s get you back on track! 
            </Typography>
            <Button 
                variant="contained" 
                color="primary" 
                component={Link} 
                to="/" 
                sx={{ marginTop: '10px', position: 'relative', zIndex: 1 }}
            >
                Go Back Home
            </Button>
            
        </Box>
    );
};

export default NotFound;
