import React from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';

function HeroSection() {
    return (
        <Box 
            sx={{
                backgroundImage: `url('/pollen-bg.jpg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '70vh',
                padding: '50px 20px',
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.6)',
                    zIndex: -1,
                },
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={9}>
                        <Typography variant="h2" sx={{ color: '#fff', marginBottom: '20px', fontWeight: 'bold' }}>
                            Welcome to Live Safe.
                        </Typography>
                        <Typography variant="h4" sx={{ color: '#fff', marginBottom: '30px' }}>
                            Your Trusted Health Companion in Melbourne
                        </Typography>
                        <Typography variant="h5" sx={{ color: '#fff', marginBottom: '30px' }}>
                            Stay informed about common allergies in Melbourne during pollen season. 
                            Learn how to identify symptoms, avoid triggers and manage allergic reactions.
                        </Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            href="/health"
                            size="large"
                            aria-label="Learn about Melbourne's health challenges"
                        >
                            Learn More
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default HeroSection;
