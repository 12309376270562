import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  LinearProgress,
  Box,
  Grid
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";


const PollenDataDisplay = ({ pollenData, selectedDay }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  if (!pollenData) return null;

  const dayInfo = pollenData.dailyInfo[selectedDay];

  // Function to open modal for image zoom
  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ padding: 4, maxWidth: "100%" }}>
      {" "}
      {/* Remove maxWidth restriction */}
      <Typography variant="h5" gutterBottom>
        Daily Pollen and Plant Information for{" "}
        {`${dayInfo.date.day}/${dayInfo.date.month}/${dayInfo.date.year}`}
      </Typography>
      {/* Pollen Type Information Section */}
      {dayInfo.pollenTypeInfo && (
        <Box sx={{ mb: 6, width: "100%", maxWidth: 1200, mx: "auto" }}>
          <Typography variant="h5" color="primary" gutterBottom>
            🌳 Pollen Type Information
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ flexGrow: 1, mt: 4 }}
          >
            {dayInfo.pollenTypeInfo[0] && (
              <Grid>
                {" "}
                {/* Wider grid items for larger screens */}
                <Card variant="outlined" sx={{ boxShadow: 4, p: 3 }}>
                  <CardContent>
                    <Typography variant="h6">
                      <strong>{dayInfo.pollenTypeInfo[0].displayName}</strong>
                    </Typography>
                    {dayInfo.pollenTypeInfo[0].indexInfo?.category ? (
                      <>
                        <Tooltip
                          title={
                            dayInfo.pollenTypeInfo[0].indexInfo.indexDescription
                          }
                        >
                          <Typography variant="body1">
                            {dayInfo.pollenTypeInfo[0].indexInfo.category} (Universal Pollen Index:{" "}
                            {dayInfo.pollenTypeInfo[0].indexInfo.value})
                          </Typography>
                        </Tooltip>
                        <LinearProgress
                          variant="determinate"
                          value={dayInfo.pollenTypeInfo[0].indexInfo.value * 10}
                          color={
                            dayInfo.pollenTypeInfo[0].indexInfo.value >= 8
                              ? "error"
                              : dayInfo.pollenTypeInfo[0].indexInfo.value >= 5
                              ? "warning"
                              : "success"
                          }
                          sx={{ height: 40, borderRadius: 5, mt: 1 }}
                        />
                      </>
                    ) : (
                      <Typography variant="body1">
                        No category information available
                      </Typography>
                    )}
                    {dayInfo.pollenTypeInfo[0].healthRecommendations && (
                      <Box sx={{ mt: 2 }}>
                        {/* Recommendations Card */}
                        <Card
                          variant="outlined"
                          sx={{
                            p: 2,
                            boxShadow: 6,
                            backgroundColor: "success.light",
                          }}
                        >
                          <Typography variant="body1" color="textPrimary">
                            🌟 Recommendations:{" "}
                            {dayInfo.pollenTypeInfo[0].healthRecommendations.join(
                              ", "
                            )}
                          </Typography>
                        </Card>

                        {/* Precautionary Warning Card */}
                        <Card
                          variant="outlined"
                          sx={{
                            p: 2,
                            boxShadow: 6,
                            backgroundColor: "warning.light",
                            mt: 2, // Add space between the two cards
                          }}
                        >
                          <Typography variant="body1" color="textPrimary">
                            ⚠️ Precautionary Warning:{" "}
                            {
                              dayInfo.pollenTypeInfo[0].indexInfo
                                .indexDescription
                            }
                          </Typography>
                        </Card>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {/* Plant Information Section */}
      {dayInfo.plantInfo && dayInfo.plantInfo[0] && (
        <Box sx={{ mb: 6, width: "100%" }}>
          <Typography variant="h5" color="primary" gutterBottom>
            🌲 Detailed Plant Information and Health Tips
          </Typography>

          <Card variant="outlined" sx={{ boxShadow: 4, p: 3 }}>
            <CardContent>
              {/* Plant Name */}
              <Typography variant="h5" sx={{ mb: 2 }}>
                <strong>{dayInfo.plantInfo[0].displayName}</strong> - Learn More
                About This Plant
              </Typography>

              {/* Precautionary Warning */}
              <Typography variant="body1" color="textPrimary" sx={{ mt: 1 }}>
                ⚠️ <strong>Precautionary Warning:</strong> Be cautious if you're
                sensitive to pollen.{" "}
                {dayInfo.plantInfo[0].indexInfo.indexDescription}
              </Typography>

              <Card
                variant="outlined"
                sx={{
                  p: 2,
                  boxShadow: 6,
                  backgroundColor: "",
                  mt: 1, // Add space between the two cards
                }}
              >
                {/* Descriptive Heading */}
                <Typography variant="h5" sx={{ mb: 2 }}>
                  🌳 About This Plant
                </Typography>

                {/* Plant Type with more context */}
                {dayInfo.plantInfo[0].plantDescription.type && (
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>Plant Type:</strong> This is classified as a{" "}
                    {dayInfo.plantInfo[0].plantDescription.type}. Understanding
                    the type helps identify potential allergens.
                  </Typography>
                )}

                {/* Plant Family with explanation */}
                {dayInfo.plantInfo[0].plantDescription.family && (
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>Family:</strong>{" "}
                    {dayInfo.plantInfo[0].plantDescription.family}. Plants from
                    this family may cause similar allergic reactions.
                  </Typography>
                )}

                {/* Plant Season with dynamic context based on season */}
                {dayInfo.plantInfo[0].plantDescription.season && (
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>Active Season:</strong>{" "}
                    {dayInfo.plantInfo[0].plantDescription.season}.
                    {dayInfo.plantInfo[0].plantDescription.season
                      .toLowerCase()
                      .includes("late spring") ||
                    dayInfo.plantInfo[0].plantDescription.season
                      .toLowerCase()
                      .includes("early summer") ? (
                      <>
                        {" "}
                        Pollen levels are typically the highest during late
                        spring and early summer. Individuals sensitive to
                        pollen, especially grass pollen, may experience more
                        symptoms during these periods.
                      </>
                    ) : (
                      <>
                        {" "}
                        This plant may not have as significant an impact on
                        pollen levels outside of late spring and early summer.
                      </>
                    )}
                  </Typography>
                )}

                {/* Special Shapes with explanation */}
                {dayInfo.plantInfo[0].plantDescription.specialShapes && (
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>Unique Physical Characteristics:</strong>{" "}
                    {dayInfo.plantInfo[0].plantDescription.specialShapes} The
                    unique shape of the plant helps with its identification.
                  </Typography>
                )}

                {/* Cross-Reaction with more context */}
                {dayInfo.plantInfo[0].plantDescription.crossReaction && (
                  <Typography variant="body1" sx={{ mt: 1 }} color="error">
                    ⚠️ <strong>Cross-Reactivity Warning:</strong>{" "}
                    {dayInfo.plantInfo[0].plantDescription.crossReaction}. If
                    you have allergies to these related plants or foods, take
                    additional precautions.
                  </Typography>
                )}
              </Card>

              {dayInfo.plantInfo[0].plantDescription.picture && (
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    gap: 2,
                    justifyContent: "center", // Center horizontally
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={dayInfo.plantInfo[0].plantDescription.picture}
                    alt={`${dayInfo.plantInfo[0].displayName} full view`}
                    sx={{ width: 180, cursor: "pointer" }}
                    onClick={() =>
                      openModal(dayInfo.plantInfo[0].plantDescription.picture)
                    }
                  />
                  {dayInfo.plantInfo[0].plantDescription.pictureCloseup && (
                    <CardMedia
                      component="img"
                      image={
                        dayInfo.plantInfo[0].plantDescription.pictureCloseup
                      }
                      alt={`${dayInfo.plantInfo[0].displayName} close-up`}
                      sx={{ width: 180, cursor: "pointer" }}
                      onClick={() =>
                        openModal(
                          dayInfo.plantInfo[0].plantDescription.pictureCloseup
                        )
                      }
                    />
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
      {/* Dialog for image zoom */}
      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <CardMedia
              component="img"
              image={selectedImage}
              alt="Full view"
              sx={{ width: "100%" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PollenDataDisplay;
