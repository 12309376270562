import React from 'react';

const footerStyle = {
    backgroundColor: '#222831',
    color: '#fff',
    padding: '20px 0',
    textAlign: 'center',
    width: '100%',
    zIndex: 1,
    position: 'relative'
};

const containerStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px',
};

const paragraphStyle = {
    margin: '0',
    fontSize: '14px',
    lineHeight: '1.5',
};

function Footer() {
    return (
        <footer style={footerStyle}>
            <div style={containerStyle}>
                <p style={paragraphStyle}>
                    &copy; Copyright ©2024 All rights reserved | This website is copyrighted by TroubleShouter PTY LTD
                </p>
            </div>
        </footer>
    );
}

export default Footer;
