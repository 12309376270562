import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material'; // Importing MUI components
import Lottie from 'lottie-react';
import aboutAnimation from './lottie-aboutus.json';

function AboutSection() {
    return (
        <Box 
            sx={{
                backgroundColor: '#2e3642', // Background color
                padding: '20px 0', // Reduced padding to make it more compact
                minHeight: 'auto', // Let the content define the height
            }}
        >
            <Container>
                <Grid container alignItems="center" justifyContent="space-between">
                    {/* About Us Text Section */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{ color: '#FFFFFF', marginBottom: '15px', fontSize: '1.5rem' }}>
                            About Us
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#FFFFFF', fontSize: '1rem', lineHeight: '1.6' }}>
                            At Live Safe, we help recent migrant women stay healthy and safe by providing easy-to-understand information about health and well-being, including pollen levels. Our mission is to make it simple for individuals and communities to make informed choices and stay protected.
                        </Typography>
                    </Grid>
                    
                    {/* Lottie Animation Section */}
                    <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Lottie
                            animationData={aboutAnimation}
                            style={{ height: '200px', width: '200px' }} // Reduced the animation size
                            loop={true}
                            autoplay={true}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default AboutSection;
