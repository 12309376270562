import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Collapse,
  Grid,
  Container,
  Alert,
  AlertTitle,
  CardMedia,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { 
  CloudUpload, 
  CheckCircle, 
  Warning, 
  ExpandMore, 
  ExpandLess,
  LocalFlorist,
  Delete,
  ZoomIn,
} from '@mui/icons-material';

import DOMPurify from 'dompurify'

const plantInfo = {
  0: {
    name: 'Ragweed',
    description: 'While not native to Melbourne, ragweed has become naturalized in some areas. Its pollen is highly allergenic and can trigger severe hay fever symptoms, especially from late summer to early autumn.',
    asthmaRisk: 'High',
    image: 'ragweedplant.jpg',
  },
  1: {
    name: 'Cypress Tree',
    description: 'Cypress trees, including the native Callitris species, are common in Melbourne. They release pollen in late winter and early spring, potentially causing moderate to severe allergic reactions in sensitive individuals.',
    asthmaRisk: 'Moderate to High',
    image: 'new.jpg',
  },
  2: {
    name: 'Pine Tree',
    description: "While not native, pine trees are widely planted in Melbourne's parks and gardens. They produce large amounts of pollen in spring, but it's generally less allergenic than other types. Some people may still experience mild allergic reactions.",
    asthmaRisk: 'Low to Moderate',
    image: 'pinetree.jpeg',
  },
  3: {
    name: 'Olive Tree',
    description: 'Olive trees are common in Melbourne, both as ornamental plants and in some orchards. They release pollen in late spring and early summer, which can cause severe allergic reactions and exacerbate asthma symptoms in sensitive individuals.',
    asthmaRisk: 'High',
    image: 'olive.jpg',
  }
};

const MAX_FILE_SIZE = 5 * 1024 * 1024; // Size 5MB
const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/bmp',
];

const ALLOWED_FILE_EXTENSIONS = '.jpg, .jpeg, .png, .tiff, .bmp';


const AllergyRecognitionPage = () => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const steps = ['Upload Image', 'Analyze Plant', 'View Results'];
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const drawDetectionsForPopup = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imagePreview;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      analysisResults.forEach((detection, index) => {
        if (detection.bbox && detection.bbox.length === 4) {
          const [x, y, width, height] = detection.bbox;
          const color = `hsl(${index * 137.5 % 360}, 70%, 50%)`;
          ctx.strokeStyle = color;
          ctx.lineWidth = 3;
          ctx.strokeRect(x, y, width, height);

          ctx.fillStyle = color;
          ctx.font = '16px Arial';
          ctx.fillText(detection.name, x, y - 5);
        }
      });
    };
    return canvas;
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    
    if (!selectedFile) return;

    if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      setError(`Invalid file type. Please upload one of the following formats: ${ALLOWED_FILE_EXTENSIONS}`);
      return;
    }

    if (selectedFile.size > MAX_FILE_SIZE) {
      setError('File size exceeds 5MB limit. Please upload a smaller image.');
      return;
    }

    setFile(selectedFile);
    setAnalysisResults(null);
    setError(null);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      drawImage(reader.result);
    };
    reader.readAsDataURL(selectedFile);
    setActiveStep(1);
  };
  const drawImage = (imageSrc) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      // Set canvas size to match the image
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image
      ctx.drawImage(image, 0, 0, image.width, image.height);
    };
  };
  const handleUpload = async () => {
    setIsAnalyzing(true);
    setError(null);
  
    try {
      // Convert the image file to base64
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1]; // Get the base64 string
        const requestBody = {
          body: base64Image,
        };
        console.log("Base64 image:", base64Image);

  
        const response = await axios.post(
          'https://k34as1li2i.execute-api.us-east-1.amazonaws.com/new',
          JSON.stringify(requestBody),
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: 30000,
          }
        );
        
        console.log("RD: ",response.data);
        const detections = JSON.parse(response.data.body).detections || [];
  
        if (detections.length === 0) {
          setError('No plants detected. Please try uploading a clearer image.');
          setAnalysisResults([]); 
        } else {
          const highConfidenceResults = detections.filter(detection => detection.confidence > 0.60);
  
          if (highConfidenceResults.length > 0) {
            const uniqueResults = highConfidenceResults.map(detection => ({
              ...plantInfo[detection.class],
              bbox: detection.bbox,
              confidence: detection.confidence,
            }));
  
            setAnalysisResults(uniqueResults);
            drawDetections(uniqueResults);
            setActiveStep(2);
          } else {
            setError('No clear detections found. Please try again with a clearer image.');
          }
        }
      };
  
      reader.readAsDataURL(file); // Read the file as a data URL (base64)
  
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        setError('Request timed out. Please try again.');
      } else {
        setError('An error occurred during analysis. Please upload image according to the guidelines.');
      }
      console.error('Error during image analysis:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };
  

  const drawDetections = (detections) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imagePreview;
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      detections.forEach((detection, index) => {
        if (detection.bbox && detection.bbox.length === 4) {
          const [x, y, width, height] = detection.bbox;
          const color = `hsl(${index * 137.5 % 360}, 70%, 50%)`;
          ctx.strokeStyle = color;
          ctx.lineWidth = 10;
          ctx.strokeRect(x, y, width, height);

          // Add label
          ctx.fillStyle = color;
          ctx.font = '65px Arial';
          ctx.fillText(detection.name, x, y +150);
        }
      });
    };
  };

  const handleClearImage = () => {
    setFile(null);
    setImagePreview(null);
    setAnalysisResults(null);
    setError(null);
    setActiveStep(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };
  
  useEffect(() => {
    if (imagePreview && analysisResults && analysisResults.length > 0) {
      drawDetections(analysisResults);
    }
  }, [imagePreview, analysisResults]);

  const sanitizeHtml = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <Container maxWidth="lg">
      <Box my={2}>
        <Box sx={{
          backgroundColor: '#395B90',
          color: 'white',
          padding: 3,
          borderRadius: 2,
          textAlign: 'center',
          boxShadow: 3,
          mb: 4,
          '& h1': {
            fontSize: '2.5rem',
            fontWeight: 'bold',
            textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
          }
        }}>
          <Typography variant="h3" sx={{ color: "#FFFFFF", mb: 1 }}>
            Pollen Allergen Detection
          </Typography>
          <Typography variant="h5" sx={{ color: "#FFFFFF", mb: 1 }}>
            Identify potential pollen allergens in plants around you
          </Typography>
        </Box>

        <Card sx={{ mb: 4 }}>
         <CardContent>
         <Typography variant="h5" sx={{ color: "#2f5d62", mb: 1 }}>
             How It Works
           </Typography>
           <Typography variant="body1" paragraph>
           This tool helps you identify potential pollen-allergenic plants in your area. Just upload a clear photo of a plant taken outdoors, and our image recognition model will analyze it for common allergens.
          
           </Typography>
           <Typography variant="body1" paragraph>
            Currently, it identifies Ragweed, Cypress trees, Pine tree and Olive trees. To know more about these plants click on plant allergies section below.
           </Typography>
           <Typography variant="body1" paragraph>
             Ideal photos should:
           </Typography>
           <List>
             <ListItem>
               <ListItemIcon>
                 <CheckCircle color="primary" />
               </ListItemIcon>
               <ListItemText primary="Be well-lit and in focus" />
             </ListItem>
             <ListItem>
               <ListItemIcon>
                 <CheckCircle color="primary" />
               </ListItemIcon>
               <ListItemText primary="Show the entire plant or a significant portion" />
             </ListItem>
             <ListItem>
               <ListItemIcon>
                 <CheckCircle color="primary" />
               </ListItemIcon>
               <ListItemText primary="Ensure the image size is less than 5MB. and image has one of these extension: .jpg, .jpeg, .png, .heif, .tiff, .bmp " />
             </ListItem>
           </List>
         </CardContent>
       </Card>

       <Typography
            variant="body2"
            color="primary"
            sx={{
              mb: 2,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.2rem",
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "8px",
              boxShadow: 3,
            }}
          >
            ⬇ Scroll down to access the image upload section ⬇
          </Typography>

        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box 
                sx={{ 
                  border: '2px dashed #ccc', 
                  borderRadius: 2, 
                  p: 3, 
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: 'primary.main',
                  },
                }}
                onClick={() => fileInputRef.current.click()}
              >
                {imagePreview ? (
                  <>
                    <canvas ref={canvasRef} style={{ maxWidth: '100%', maxHeight: 300 }} />
                    {analysisResults && analysisResults.length > 0 && (
                      <>
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                          Click to view full image with detections
                        </Typography>
                        <Button
                          variant="outlined"
                          startIcon={<ZoomIn />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenPopup();
                          }}
                          sx={{ mt: 1 }}
                        >
                          View Full Image
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <CloudUpload sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                    <Typography>Click or drag to upload an image</Typography>
                  </>
                )}
              </Box>
                <input
                  ref={fileInputRef}
                  id="file-input"
                  type="file"
                  accept={ALLOWED_FILE_TYPES.join(',')}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <Alert severity="info" sx={{ mt: 2 }}>
                  <AlertTitle>Privacy Notice</AlertTitle>
                  We do not store any uploaded images. Your privacy is our priority.
                </Alert>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleUpload}
                    disabled={!file || isAnalyzing}
                    sx={{ flexGrow: 1, mr: 1 }}
                  >
                    {isAnalyzing ? <CircularProgress size={24} /> : 'Analyze Plant'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    onClick={handleClearImage}
                    disabled={!file}
                    startIcon={<Delete />}
                  >
                    Clear
                  </Button>
                </Box>
              </CardContent>
            </Card>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

{analysisResults && analysisResults.length > 0 && (
  <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
    <Typography variant="h6" gutterBottom>
      Analysis Results
    </Typography>
    
    {/* Create a Set to track unique plant names */}
    {Array.from(new Set(analysisResults.map(result => result.name))).map((plantName) => {
      const plantDetails = analysisResults.find(result => result.name === plantName);

      return (
        <Box key={plantName} mb={2}>
          <List>
            <ListItem>
              <ListItemIcon>
                <LocalFlorist color="primary" />
              </ListItemIcon>
              <ListItemText primary="Plant Identified" secondary={plantName} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Warning color={plantDetails.asthmaRisk === 'High' ? 'error' : 'warning'} />
              </ListItemIcon>
              <ListItemText primary="Asthma Risk" secondary={plantDetails.asthmaRisk} />
            </ListItem>
          </List>
          <Typography variant="body1" dangerouslySetInnerHTML={sanitizeHtml(plantDetails.description)} />
        </Box>
                     );
                    })}
                <Box sx={{ mt: 4, p: 2,background:'#fdffd5', border: '1px solid #ccc', borderRadius: 2 }}>
     <Typography variant="h5" gutterBottom>
       Take Your Allergy Risk Assessment
     </Typography>
     <Typography variant="body1" paragraph>
       Based on the plants identified, you may be at risk for pollen allergies. Take our quick risk assessment quiz to learn more about your allergy risk and recommended precautions.
     </Typography>
     <Button
       variant="contained"
       color="primary"
       href="/allergy"
     >
       Start Risk Assessment
     </Button>
   </Box>
                
              </Paper>
            )}

          </Grid>
          <Grid item xs={12} md={6}>
          <Card>
   <CardContent>
     <Typography variant="h5" component="h2" gutterBottom>
       About Plant Allergies
     </Typography>
     <Typography variant="body1" paragraph>
       Different plants can cause various allergic reactions. Here's some information about common allergenic plants:
     </Typography>
   
     <Box mt={2} sx={{ 
  maxHeight: '485px', 
  overflowY: 'scroll', // Change to 'scroll' to always show the scrollbar
        
  '&::-webkit-scrollbar': { // Customize scrollbar for WebKit browsers (Chrome, Safari)
    width: '8px',          // Width of the scrollbar
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888', // Color of the scrollbar thumb
    borderRadius: '4px',     // Rounded corners for the thumb
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555', // Darker thumb on hover
  },
}}> 
  {Object.values(plantInfo).map((plant, index) => (
    <Box key={index} mb={4}>
      <Typography variant="h6" gutterBottom>
        {plant.name}
      </Typography>
      <CardMedia
        component="img"
        height="300" // Set a fixed height
        image={plant.image}
        alt={plant.name}
        sx={{ mb: 1, borderRadius: 1, objectFit: 'contain', maxHeight: '300px', width: '100%' }} // Maintain aspect ratio and fit
      />
      <Typography variant="body2" paragraph>
        {plant.description}
      </Typography>
      <Typography variant="body2">
        Asthma Risk: {plant.asthmaRisk}
      </Typography>
    </Box>
  ))}
</Box>

   </CardContent>
 </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Plant Detection Results</DialogTitle>
        <DialogContent>
          {imagePreview && analysisResults && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img 
                src={canvasRef.current?.toDataURL()} 
                alt="Detected Plants" 
                style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }} 
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
      
    </Container>
  );
};

export default AllergyRecognitionPage;