import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import PollenDataDisplay from "./PollenDataDisplay";
import {
  Button,
  Typography,
  MenuItem,
  Select,
  Box,
  Container,
  Divider, // Import Divider from Material-UI
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL || "";

const MapContainer = styled("div")(({ theme }) => ({
  height: "500px",
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  marginTop: theme.spacing(4),
  boxShadow: theme.shadows[5],
}));

// Keyframes for the bouncing arrow animation
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

// Styled component for the bouncing arrow icon
const BouncingArrow = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: "#2f5d62",
  marginTop: theme.spacing(1),
  animation: `${bounce} 2s infinite`,
}));

const PollenHeatMap = () => {
  const [map, setMap] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [pollenData, setPollenData] = useState(null);
  const [selectedDay, setSelectedDay] = useState(0);

  // Fetch locations from the JSON file
  useEffect(() => {
    fetch("/locations.json") // Use the actual path to your locations.json file
        .then((response) => response.json())
        .then((data) => setLocations(data))
        .catch((err) => console.error("Error fetching locations:", err));
  }, []);

  useEffect(() => {
    console.log("InfoWindowPosition updated:", infoWindowPosition);
  }, [infoWindowPosition]);

  useEffect(() => {
    console.log("PollenData updated:", pollenData);
  }, [pollenData]);

  // Function to handle Google Maps loading
  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const handleLocationChange = async (event) => {
    const selectedLoc = locations.find(
        (loc) => loc.loc_name === event.target.value
    );

    if (selectedLoc) {
      console.log("Selected Location:", selectedLoc);

      // Ensure no previous infoWindowPosition state
      if (infoWindowPosition) {
        console.log("Clearing previous InfoWindowPosition");
        setInfoWindowPosition(null);
      }

      setSelectedLocation(selectedLoc);

      if (map) {
        const { latitude, longitude } = selectedLoc;

        console.log("Panning to:", { lat: latitude, lng: longitude });
        map.panTo({ lat: latitude, lng: longitude });
        map.setZoom(10);

        // Delay setting the info window to ensure the map has finished panning
        setTimeout(() => {
          console.log("Setting InfoWindowPosition:", {
            lat: latitude,
            lng: longitude,
          });
          setInfoWindowPosition({ lat: latitude, lng: longitude });
        }, 1000);

        // Fetch new pollen data for the selected location
        const pollenApiUrl = `${apiUrl}/pollen/${latitude}/${longitude}`;
        try {
          const response = await axios.get(pollenApiUrl);
          console.log("Fetched Pollen Data:", response.data);
          setPollenData(response.data);
        } catch (error) {
          console.error("Error fetching pollen data:", error);
        }
      }
    }
  };

  // Function to determine InfoWindow background color based on UPI
  const getInfoWindowColor = (upi) => {
    if (upi >= 5) return "#FF0000"; // High alert (Red)
    if (upi >= 3) return "#FFA500"; // Medium alert (Orange)
    return "#58855c"; // Low alert (Green)
  };

  return (
    <Container>
      {/* Header Section */}
      <Box
        sx={{
          textAlign: "center",
          mt: 2,
          backgroundColor: "#395B90",
          padding: "20px",
          minHeight: "auto",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h3" sx={{ color: "#FFFFFF", mb: 1 }}>
          Discover Local Pollen Data and Health Tips
        </Typography>
        <Typography variant="h5" sx={{ color: "#FFFFFF", mb: 1 }}>
          Get real-time pollen forecasts to manage allergies and stay
          comfortable.
        </Typography>
      </Box>

      {/* Map Section */}
      <Box sx={{ mt: 1, padding: "20px 0", minHeight: "auto" }}>
        <Typography variant="h5" sx={{ color: "#2f5d62", mb: 1 }}>
          Select a Location
        </Typography>
        <Typography variant="body1" sx={{ color: "#3a3939", mb: 1 }}>
          Choose your neighborhood to get personalized pollen forecasts.
        </Typography>
        <Select
          fullWidth
          value={selectedLocation?.loc_name || ""}
          onChange={handleLocationChange}
          displayEmpty // This ensures the placeholder is shown when no value is selected
          sx={{ mb: 2, backgroundColor: "#fff", borderRadius: "8px" }}
        >
          <MenuItem value="" disabled>
            <em>Click to Select a location from dropdown</em>{" "}
            {/* This is the hint */}
          </MenuItem>
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.loc_name}>
              {location.loc_name}
            </MenuItem>
          ))}
        </Select>

        {/* Conditionally display the "scroll down" message if a location is selected */}
        {selectedLocation && (
          <Typography
            variant="body2"
            color="primary"
            sx={{
              mb: 2,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.2rem",
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "8px",
              boxShadow: 3,
            }}
          >
            ⬇ Scroll down for more information ⬇
          </Typography>
        )}

        <MapContainer>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          >
            <GoogleMap
              mapContainerStyle={{ height: "100%", width: "100%" }}
              center={{ lat: -37.8136, lng: 144.9631 }}
              zoom={10}
              onLoad={onLoad}
            >
              {/* Render InfoWindow only after ensuring the state is ready */}
              {infoWindowPosition && selectedLocation && pollenData && (
                <InfoWindow position={infoWindowPosition}>
                  <div
                    style={{
                      backgroundColor: getInfoWindowColor(
                        pollenData.dailyInfo[selectedDay].pollenTypeInfo[0]
                          ?.indexInfo.value
                      ),
                      padding: "10px",
                      borderRadius: "5px",
                      color: "#fff",
                    }}
                  >
                    <h4>{selectedLocation.loc_name}</h4>
                    <p>
                      Pollen Level:{" "}
                      {
                        pollenData.dailyInfo[selectedDay].pollenTypeInfo[0]
                          ?.indexInfo.category
                      }{" "}
                      <br />
                      Universal Pollen Index:{" "}
                      {
                        pollenData.dailyInfo[selectedDay].pollenTypeInfo[0]
                          ?.indexInfo.value
                      }
                    </p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </LoadScript>
        </MapContainer>

        {/* Guidance Arrow and Message */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 2,
          }}
        >
          <BouncingArrow fontSize="large" />
          <Typography variant="body1" sx={{ color: "#2f5d62", mt: 1 }}>
            Scroll down for more information
          </Typography>
        </Box>
      </Box>

      {/* Pollen Data and Day Selector Section */}
      {pollenData && selectedLocation && (
        <>
          {pollenData.dailyInfo && pollenData.dailyInfo.length > 1 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ color: "#2f5d62" }}>
                Select Day
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {pollenData.dailyInfo.map((dayInfo, idx) => (
                  <Button
                    key={idx}
                    variant={selectedDay === idx ? "contained" : "outlined"}
                    color={selectedDay === idx ? "primary" : "default"}
                    onClick={() => setSelectedDay(idx)}
                  >
                    {`${dayInfo.date.day}/${dayInfo.date.month}/${dayInfo.date.year}`}
                  </Button>
                ))}
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ color: "#2f5d62" }}>
              Pollen Information for {selectedLocation.loc_name}
            </Typography>
            <PollenDataDisplay
              pollenData={pollenData}
              selectedDay={selectedDay}
            />
          </Box>
        </>
      )}

      {/* Link to Allergy Recognition Page */}
      <Box sx={{ mt: 0, textAlign: "center", mb: 3 }}>
        <Typography variant="h6" sx={{ color: "#2f5d62", mb: 2 }}>
          Want to identify nearby allergenic plants?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/allergyrecognition"
        >
          Go to Allergy Recognition Page
        </Button>
      </Box>
    </Container>
  );
};

export default PollenHeatMap;
