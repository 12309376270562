import React from 'react';
import { Typography, Accordion, Grid, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Box, Paper, Button,
 Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AllergyIcon from '@mui/icons-material/HealthAndSafety';
import SymptomsIcon from '@mui/icons-material/Sick';
import TriggersIcon from '@mui/icons-material/Warning';
import ManagementIcon from '@mui/icons-material/Build';
import PollenIcon from '@mui/icons-material/Grass';
import { useNavigate } from 'react-router-dom';




import allergyData from './AllergyData'; // Adjust the path if necessary


// Component to display allergy information
const AllergyInfo = ({ name, description, symptoms, triggers, management }) => (
 <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: '0 2px 5px rgba(0,0,0,0.1)', '&:before': { display: 'none' } }}>
   <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${name}-content`} id={`${name}-header`}>
     <Typography variant="h6" color="primary">
       <AllergyIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> {name}
     </Typography>
   </AccordionSummary>
   <AccordionDetails>
     <Typography variant="body1" paragraph>
       <strong>Description:</strong> {description}
     </Typography>
     <Grid container spacing={2}>
       {/* Symptoms Section */}
       <Grid item xs={12} sm={6}>
         <Box sx={{ bgcolor: '#e3f2fd', borderRadius: 1, p: 2,textAlign: 'center' }}>
           <Typography variant="subtitle1">
             <SymptomsIcon sx={{ verticalAlign: 'middle', mr: 0.5 }} /> <strong>Symptoms:</strong>
           </Typography>
           <List dense>
             {symptoms.map((symptom, index) => (
               <ListItem key={index} sx={{paddingLeft: 5, paddingBottom:0}}>
                 <ListItemText primary={symptom} />
               </ListItem>
             ))}
           </List>
         </Box>
       </Grid>


       {/* Triggers Section */}
       <Grid item xs={12} sm={6}>
         <Box sx={{ bgcolor: '#ffe0b2', borderRadius: 1, p: 2 ,  textAlign: 'center'}}>
           <Typography variant="subtitle1">
             <TriggersIcon sx={{ verticalAlign: 'middle', mr: 0.5 }} /> <strong>Triggers:</strong>
           </Typography>
           <List dense>
             {triggers.map((trigger, index) => (
               <ListItem key={index} sx={{paddingLeft: 5, paddingBottom:0}}>
                 <ListItemText primary={trigger} />
               </ListItem>
             ))}
           </List>
         </Box>
       </Grid>


       {/* Management Strategies Section */}
       <Grid item xs={12}>
         <Box sx={{ bgcolor: '#dcedc8', borderRadius: 1, p: 2, textAlign: 'center' }}>
           <Typography variant="subtitle1">
             <ManagementIcon sx={{ verticalAlign: 'middle', mr: 0.5 }} /> <strong>Management Strategies:</strong>
           </Typography>
           <List dense>
             {management.map((tip, index) => (
               <ListItem key={index} sx={{textAlign: 'center'}}>
                 <ListItemText primary={tip} />
               </ListItem>
             ))}
           </List>
         </Box>
       </Grid>
     </Grid>
   </AccordionDetails>
 </Accordion>
);


// Main component for the allergy page
const AllergyPage = () => {
 const navigate = useNavigate();




 const handleNavigateToPollenInsights = () => {
   navigate('/pollen'); // Adjust the route as necessary
 };


 return (
   <Container maxWidth="lg">
     <Box
       sx={{
         backgroundColor: '#395B90',
         color: 'white',
         padding: 3,
         borderRadius: 2,
         textAlign: 'center',
         boxShadow: 3,
         mb: 4,
         marginTop: '25px'
       }}
     >
       <Typography variant="h4" sx={{ color: '#FFFFFF' }} component="h1" gutterBottom>
         Understanding Pollen Allergies in Melbourne
       </Typography>
       <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }} paragraph>
         Learn about pollen allergies, recognize symptoms and discover management strategies.
       </Typography>
     </Box>


     <Paper elevation={1} sx={{ padding: 3, mb: 4 }}>
       <Typography variant="h5" component="h2" gutterBottom textAlign='center'>
         Why Pollen is a Problem?
       </Typography>
       <Typography variant="body1" paragraph>
         Pollen from certain plants can trigger allergic reactions in sensitive individuals. When pollen grains enter the eyes or nasal passages, they can cause seasonal allergic rhinitis (hay fever), leading to symptoms like sneezing, nasal congestion, and itchy eyes.
       </Typography>
       <Typography variant="body1" paragraph>
         Moreover, pollen can exacerbate asthma symptoms. Approximately 25% of people with hay fever also experience asthma. During the grass pollen season (October to December), there's often an increase in both asthma and hay fever symptoms.
       </Typography>
       <Typography variant="body1" paragraph>
         Understanding pollen levels and their impact is crucial for managing allergies effectively. By staying informed about daily pollen counts, you can take proactive measures to reduce exposure and minimize symptoms.
       </Typography>
     </Paper>


     <Grid container spacing={3} sx={{ mb: 4 }}>
       <Grid item xs={12} md={4}>
         <img
           src="/allergy.jpg"
           alt="Person with allergies"
           style={{ width: '100%', borderRadius: '8px' }}
         />
       </Grid>
       <Grid item xs={12} md={8}>
         <Typography variant="h5" component="h2" gutterBottom>
           Common Pollen Allergies
         </Typography>
         <Typography variant="body2" sx={{ mb: 2, fontStyle: 'italic', color: 'text.secondary' }}>
           Click on each allergy to learn more about symptoms, triggers, and management strategies.
         </Typography>
         {allergyData.map((allergy, index) => (
           <AllergyInfo key={index} {...allergy} />
         ))}
       </Grid>
     </Grid>


    
     <Paper elevation={1} sx={{ padding: 3, mb: 4 }}>
     <Box sx={{ borderRadius: 2, mb: 2 }}>
       <Typography variant="body1" paragraph textAlign="center">
         Now that you understand pollen allergies, it's important to stay updated on current pollen levels in your area. Our pollen insights provides daily forecasts of pollen index to help you plan your activities and manage your allergy symptoms effectively.
       </Typography>
       <Box textAlign="center">
         <Button
           variant="contained"
           color="primary"
           size="large"
           startIcon={<PollenIcon />}
           onClick={handleNavigateToPollenInsights}
           sx={{ mt: 2 }}
         >
           View Today's Pollen Index
         </Button>
       </Box>
       </Box>
       </Paper>
    


     <Box textAlign="center" mt={4} mb={4}>
       <Typography variant="body2" color="text.secondary">
         For personalized health advice and allergy management strategies, please consult with your local healthcare provider or general practitioner.
       </Typography>
     </Box>
   </Container>
 );
};


export default AllergyPage;