import React, { useEffect, useState, useCallback, useRef } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Paper, Grid, CircularProgress, Button } from '@mui/material';

const libraries = ['places'];
const googleMapsApiKey = 'AIzaSyBQ9LV79BsIx2z8g2MpH1uG3q82cwFHZPU';

const calculateCentroid = (coordinates) => {
  let x = 0;
  let y = 0;
  const numPoints = coordinates.length;

  for (let i = 0; i < numPoints; i++) {
    x += coordinates[i][0];
    y += coordinates[i][1];
  }

  return { lat: y / numPoints, lng: x / numPoints };
};

const MapLoader = ({ center, onMapLoad }) => (
  <GoogleMap
    mapContainerStyle={styles.mapContainerStyle}
    center={center}
    zoom={12}
    onLoad={onMapLoad}
    options={{ zoomControl: true }}
  />
);

const HospitalList = () => {
  const [center, setCenter] = useState({ lat: -37.8136, lng: 144.9631 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedType, setSelectedType] = useState(null); // New state for selected type
  const mapRef = useRef(null);
  const markersRef = useRef({});
  
  
const apiUrl = process.env.REACT_APP_API_URL || '';
  const location = useLocation();
  const postcode = location.state?.postcode || '';
  const pageTitle = location.state?.pageTitle || 'doctor'


  const fetchGeoJsonData = useCallback(async () => {
    try {
      if (postcode) {
        const response = await fetch(`${apiUrl}/geojson1?postcode=${postcode}`);
        if (response.ok) {
          const geoJson = await response.json();
          setGeoJsonData(geoJson);
          if (geoJson.features.length > 0) {
            const coordinates = geoJson.features[0].geometry.coordinates[0];
            const centroid = calculateCentroid(coordinates);
            setCenter(centroid);
            setSelectedType(pageTitle);// 
          }
        } else {
          setError('Failed to fetch boundary data.');
        }
      }
    } catch (error) {
      console.error('Error fetching GeoJSON data:', error);
      setError('Failed to fetch boundary data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [postcode]);

  const fetchNearbyMarkers = useCallback((type, color) => {
    if (mapRef.current && typeof window !== 'undefined' && window.google) {
      const service = new window.google.maps.places.PlacesService(mapRef.current);
      const centerLatLng = mapRef.current.getCenter();
      if (centerLatLng) {
        const request = {
          location: centerLatLng,
          radius: '3000',
          type,
        };

        service.nearbySearch(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const detailsPromises = results.map(place => {
              return new Promise((resolve, reject) => {
                service.getDetails({ placeId: place.place_id }, (details, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    resolve({
                      id: place.place_id,
                      position: {
                        lat: details.geometry.location.lat(),
                        lng: details.geometry.location.lng(),
                      },
                      title: details.name,
                      address: details.formatted_address,
                      rating: details.rating,
                      hours: details.opening_hours?.weekday_text || ['Not Available'],
                      phone: details.formatted_phone_number,
                      website: details.website,
                    });
                  } else {
                    reject(new Error('Failed to fetch details'));
                  }
                });
              });
            });

            Promise.all(detailsPromises).then(newMarkers => {
              setMarkers(prevMarkers => [...prevMarkers, ...newMarkers]);
              addMarkers(newMarkers, color);
            }).catch(error => {
              console.error(`Error fetching ${type} details:`, error);
              setError(`Failed to fetch ${type} information. Please try again.`);
            });
          } else {
            console.error('Places API error: ', status);
            setError(`Failed to fetch ${type}. Please try again.`);
          }
        });
      }
    }
  }, []);

  const addMarkers = (markersToAdd, color) => {
    markersToAdd.forEach(marker => {
      const mapMarker = new window.google.maps.Marker({
        position: marker.position,
        map: mapRef.current,
        title: marker.title,
        icon: {
          url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
        },
      });

      mapMarker.addListener('click', () => {
        setSelectedMarker(marker);
        highlightMarker(marker.id);
      });

      markersRef.current[marker.id] = {
        marker: mapMarker,
        originalIcon: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
      };
    });
  };

  const highlightMarker = (markerId) => {
    Object.keys(markersRef.current).forEach((id) => {
      const { marker, originalIcon } = markersRef.current[id];
      if (id === markerId) {
        marker.setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png');
      } else {
        marker.setIcon(originalIcon);
      }
    });
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;

    if (geoJsonData) {
      const highlightBounds = new window.google.maps.LatLngBounds();
      geoJsonData.features.forEach((feature) => {
        feature.geometry.coordinates[0].forEach(([lng, lat]) => {
          highlightBounds.extend(new window.google.maps.LatLng(lat, lng));
        });
      });
      map.fitBounds(highlightBounds);
      map.data.addGeoJson(geoJsonData);
      map.data.setStyle({
        fillColor: '#e7c8a0',
        strokeColor: 'grey',
        strokeWeight: 2,
        fillOpacity: 0.6,
      });
    }

    if (selectedType) {
      fetchNearbyMarkers(selectedType, selectedType === 'doctor' ? 'blue' : 'red');
    }
  }, [geoJsonData, fetchNearbyMarkers, selectedType]);

  useEffect(() => {
    setLoading(true);
    fetchGeoJsonData();
  }, [fetchGeoJsonData, postcode]);

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setMarkers([]); // Clear previous markers
    if (mapRef.current) {
      fetchNearbyMarkers(type, type === 'doctor' ? 'blue' : 'red'); // Fetch markers for the selected type
    }
  };

  const renderRating = (rating) => {
    if (!rating) return 'No rating available';
    return (
      <Box>
        {Array.from({ length: 5 }, (_, index) => (
          <span key={index}>
            {index < rating ? '⭐' : '☆'}
          </span>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={styles.container}>
        {pageTitle === 'doctor' && (
      <Typography variant="h4" component="h1" sx={styles.title}>
      General Practitioner Doctors in  Postcode: {postcode}
      </Typography>
      )}
       {pageTitle === 'pharmacy' && (
      <Typography variant="h4" component="h1" sx={styles.title}>
     Pharmacies in  Postcode: {postcode}
      </Typography>
      
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
      {pageTitle === 'doctor' && (
    <Typography variant="h6" component="h2">
    Looking for nearby Pharmacies? 
    </Typography>
  )}
  {pageTitle === 'pharmacy' && (
    <Typography variant="h6" component="h2">
    Looking for nearby Doctors?    
    </Typography>
  )}
      {pageTitle === 'pharmacy' && (
            <Button variant="contained" onClick={() => handleTypeSelect('doctor')}sx={{ ml: 2 }}>
                Find Doctors
            </Button>
        )}
        {pageTitle === 'doctor' && (
            <Button variant="contained" onClick={() => handleTypeSelect('pharmacy')} sx={{ ml: 2 }}>
               Find Pharmacies
            </Button>
        )}
         </Box>
      {selectedType && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
              <MapLoader center={center} onMapLoad={onMapLoad} />
            </LoadScript>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={styles.infoPanel}>
              {loading ? (
                <Box sx={styles.loading}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {selectedMarker ? (
                    <>
                      <Typography variant="h5" sx={styles.markerTitle}>{selectedMarker.title}</Typography>
                      <Typography variant="body1"><strong>Address:</strong> {selectedMarker.address}</Typography>
                      <Typography variant="body1"><strong>Rating:</strong> {renderRating(selectedMarker.rating)}</Typography>
                      <Typography variant="body1"><strong>Phone:</strong> {selectedMarker.phone || 'Not available'}</Typography>
                      <Typography variant="body1"><strong>Operating Hours:</strong></Typography>
                      <ul>
                        {selectedMarker.hours.length > 0 ? selectedMarker.hours.map((hour, index) => (
                          <li key={index}>{hour}</li>
                        )) : <li>Not available</li>}
                      </ul>
                      {selectedMarker.website && (
                        <Button variant="contained" color="primary" onClick={() => window.open(selectedMarker.website)}>
                          Visit Website
                        </Button>
                      )}
                    </>
                  ) : (
                    <Typography variant="body2">Click on marker to see details</Typography>
                  )}
                </>
              )}
              {error && <Typography color="error">{error}</Typography>}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  mapContainerStyle: {
    width: '100%',
    height: '600px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  infoPanel: {
    padding: '20px',
    borderRadius: '8px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  markerTitle: {
    marginBottom: '10px',
    color: '#1976d2',
  },
};

export default HospitalList;
