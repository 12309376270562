const allergyData = [
  {
    name: "Hay Fever (Allergic Rhinitis)",
    description: "Hay fever is an allergic reaction to pollen and other allergens in the air. It is common during spring when pollen levels are high.",
    symptoms: ["Sneezing", "Runny nose", "Itchy eyes", "Congestion"],
    triggers: ["High pollen levels", "Windy days", "Outdoor exposure","  ","  ", "  "],
    management: [
      "Wear a mask outdoors during peak pollen seasons.",
      "Use antihistamines as prescribed by a doctor.",
      "Keep windows closed and use air conditioning with a pollen filter.",
      "Shower and change clothes after spending time outdoors."


    ],
  },
  {
    name: "Asthma",
    description: "Asthma is a chronic condition that affects the airways, causing difficulty in breathing. Pollen, dust, and cold air can trigger asthma attacks.",
    symptoms: ["Wheezing", "Shortness of breath", "Coughing", "Tight chest"],
    triggers: ["Pollen", "Dust", "Cold air", "Exercise"],
    management: [
      "Always carry an inhaler.",
      "Avoid strenuous activities outdoors on high pollen days.",
      "Use air purifiers indoors and keep windows closed.",
      "Follow your asthma action plan as prescribed."
    ],
  },
  {
    name: "Skin Allergies",
    description: "Skin allergies may cause itching, rashes, and irritation triggered by pollen and other allergens that come into contact with the skin.",
    symptoms: ["Itchy skin", "Redness", "Rashes", "Swelling"],
    triggers: ["Pollen", "Mold", "Certain fabrics","  ","  ",],
    management: [
      "Apply hypoallergenic moisturizers.",
      "Wear long sleeves and pants to minimize skin exposure.",
      "Use topical corticosteroids as prescribed by a doctor.",
      "Avoid known triggers such as certain detergents or fabrics."
    ],
  },
];


export default allergyData;