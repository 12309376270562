import React, { useState, useEffect } from 'react';

const PasswordPrompt = ({ onPasswordVerified }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const isVerified = localStorage.getItem('isPasswordVerified');
        if (isVerified === 'true') {
            onPasswordVerified(true);
        }
    }, [onPasswordVerified]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const correctPassword = 'ta05';

        if (password === correctPassword) {
            localStorage.setItem('isPasswordVerified', 'true');
            onPasswordVerified(true);
        } else {
            setError('Incorrect password. Please try again.');
        }
    };

    return (
        <div style={styles.passwordContainer}>
            <h2 style={styles.title}>Enter Password to Continue</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    style={styles.passwordInput}
                    placeholder="Enter password"
                />
                <button type="submit" style={styles.submitButton}>Submit</button>
                {error && <p style={styles.error}>{error}</p>}
            </form>
        </div>
    );
};

const styles = {
    passwordContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
    },
    title: {
        fontSize: '24px',
        marginBottom: '20px',
    },
    passwordInput: {
        padding: '10px',
        fontSize: '16px',
        marginBottom: '10px',
        width: '250px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    submitButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
};

export default PasswordPrompt;