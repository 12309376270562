import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import pollenAnimation from './pollen.json';
import healthAnimationData from './lottie-health.json';
import healthAnimation from './lottie-location.json';
import allergyAnimation from './lottie-notes.json';
import { Container, Grid, Typography, Box, Button, TextField } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL || '';

function FeaturedServices() {
    const [postcode, setPostcode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!/^\d{4}$/.test(postcode)) {
            setError('Postcode must be a 4-digit number');
            return;
        }
        setError('');
        try {
            const response = await fetch(`${apiUrl}/geojson1?postcode=${postcode}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (data.features && data.features.length > 0) {
                navigate('/places', { state: { postcode, suburbBoundary: data, pageTitle: 'doctor' } });
            } else {
                setError('No data found for the given postcode');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data. Please try again.');
        }
    };

    return (
        <Box id = 'featured-services'
            sx={{
                backgroundColor: '#f0f8ff',
                padding: '50px 0',
            }}
        >
            <Container>
                <Typography 
                    variant="h3" 
                    sx={{ 
                        color: '#2f5d62', 
                        textAlign: 'center', 
                        marginBottom: '40px', 
                        fontWeight: 'bold' 
                    }}
                >
                    Our Featured Services
                </Typography>

                <Grid container spacing={4} justifyContent="center">
                    {/* Pollen Allergens Detection */}
                    <Grid item xs={12} md={3}>
                        <Box 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#fff', 
                                borderRadius: '12px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                padding: '30px',
                                height: '460px', // Fixed height
                                textAlign: 'center',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <Lottie 
                                animationData={healthAnimationData} 
                                style={{ height: '200px', width: '150px' }} 
                                loop={true} 
                                autoplay={true} 
                            />
                            <Typography variant="h5" sx={{ color: '#2f5d62', marginBottom: '10px', fontWeight: 'bold' }}>
                             Allergy Detection
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#3a3939', marginBottom: '20px' }}>
                                Spot pollen allergens in your area with ease.
                            </Typography>
                            <Button variant="contained" color="primary" href="/allergyrecognition" sx={{marginTop: 'auto', marginBottom: '1px', width: '100%' }}>
                                Get Started
                            </Button>
                        </Box>
                    </Grid>

                    {/* Pollen Insights */}
                    <Grid item xs={12} md={3}>
                        <Box 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#fff', 
                                borderRadius: '12px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                padding: '30px',
                                height: '460px', // Fixed height
                                textAlign: 'center',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <Lottie 
                                animationData={pollenAnimation} 
                                style={{ height: '205px', width: '150px' }} 
                                loop={true} 
                                autoplay={true} 
                            />
                            <Typography variant="h5" sx={{ color: '#2f5d62', marginBottom: '10px', fontWeight: 'bold' }}>
                                Pollen Insights
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#3a3939', marginBottom: '20px' }}>
                                Get real-time insights about pollen levels in your area. Explore now!
                            </Typography>
                            <Button variant="contained" color="primary" href="/pollen" sx={{ marginTop: 'auto', marginBottom: '1px', width: '100%' }}>
                                Get Started
                            </Button>
                        </Box>
                    </Grid>

                    {/* Allergy Risk Assessment */}
                    <Grid item xs={12} md={3}>
                        <Box 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#fff', 
                                borderRadius: '12px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                padding: '30px',
                                height: '460px', // Fixed height
                                textAlign: 'center',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <Lottie 
                                animationData={allergyAnimation} 
                                style={{ height: '195px', width: '150px' }} 
                                loop={true} 
                                autoplay={true} 
                            />
                            <Typography variant="h5" sx={{ color: '#2f5d62', marginBottom: '10px', fontWeight: 'bold' }}>
                                Allergy Risk Assessment
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#3a3939', marginBottom: '20px' }}>
                                Take this quiz to know about pollen allergy risk and precautions.
                            </Typography>
                            <Button variant="contained" color="primary" href="/allergy" sx={{marginTop: 'auto', marginBottom: '1px', width: '100%' }}>
                                Get Started
                            </Button>
                        </Box>
                    </Grid>

                    {/* GP and Pharmacy Services */}
                    <Grid item xs={12} md={3}>
                        <Box 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#fff', 
                                borderRadius: '12px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                padding: '30px',
                                height: '460px', // Fixed height
                                textAlign: 'center',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <Lottie 
                                animationData={healthAnimation} 
                                style={{ height: '125px', width: '150px', marginBottom: '30px'}} 
                                loop={true} 
                                autoplay={true} 
                                
                            />
                            <Typography variant="h5" sx={{ color: '#2f5d62', marginBottom: '10px', fontWeight: 'bold' }}>
                                Health Services
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#3a3939', marginBottom: '20px' }}>
                                Enter your postcode to find nearby GP along with pharmacies.
                            </Typography>
                            
                            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                    label="Enter postcode"
                                    variant="outlined"
                                    value={postcode}
                                    onChange={(e) => setPostcode(e.target.value)}
                                    error={Boolean(error)}
                                    helperText={error}
                                    sx={{ marginBottom: '16px' }}
                                />
                                <Button variant="contained" color="primary" type="submit" sx={{ marginTop: 'auto', marginBottom: '1px', width: '100%' }}>
                                   Search
                                </Button>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default FeaturedServices;
